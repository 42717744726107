import 'vite/modulepreload-polyfill';
import '@tabler/core/src/js/tabler.js';
import 'htmx.org';

import '../js/main.js';
import '../js/common/themePage.js';
import '../js/algolia/app.js';
import '../js/common/notifications.js';
import initMessageCounters from './form/textArea.ts';
import phoneNumberInput from "../js/widgets/phoneField.js";

// @ts-ignore
import white_logo_tagline from '../icon/en/logo/white_logo_tagline.png'
import {Notyf} from "notyf";

// @ts-ignore
import Alpine from "alpinejs";

// @ts-ignore
window.Alpine = Alpine;
Alpine.start();

export const notyf = new Notyf(
    {
        duration: 0,
        dismissible: true,
        position: {
            x: 'right',
            y: 'top',
        },
        types: [
            {
                type: 'success',
                duration: 6000,
                background: '#2fb344',
                icon: {
                    className: "ti ti-circle-check-filled tb-alert-i",
                    tagName: "i",
                    color: "white",
                }
            },
            {
                type: 'info',
                background: '#4299e1',
                duration: 6000,
                icon: {
                    className: "ti ti-info-circle-filled tb-alert-i",
                    tagName: "i",
                    color: "white"
                }
            },
            {
                type: 'warning',
                background: '#f76707',
                icon: {
                    className: "ti ti-alert-circle-filled tb-alert-i",
                    tagName: "i",
                    color: "white"
                }
            },
            {
                type: 'error',
                background: '#d63939',
                icon: {
                    className: "ti ti-xbox-x-filled tb-alert-i",
                    tagName: "i",
                    color: "white"
                }
            }
        ]
    }
);

// @ts-ignore
(window as any).notyf = notyf;

const brandLogo = document.getElementById('navbar-brand-image') as HTMLImageElement | null;
if (brandLogo && !brandLogo.src) {
    brandLogo.src = white_logo_tagline;
}

function initPhoneField() {
    document.querySelectorAll<HTMLTextAreaElement>('input.textarea-with-mobile-number').forEach((input: HTMLTextAreaElement) => {
        const formId = input.form?.id;

        if (!formId) {
            console.error('Form id not found');
            return;
        }

        const sbmtBtn = document.querySelector(`button[type="submit"][form="${formId}"]`) as HTMLButtonElement;
        phoneNumberInput(input.id, sbmtBtn, true);
    });
}

document.addEventListener('DOMContentLoaded', () => {
  initMessageCounters();
  initPhoneField();
});

document.body.addEventListener("htmx:afterSwap", () => {
    initMessageCounters();
    // TODO: Fix frontend mistake when re-init the phone widget
    initPhoneField();
});
